import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=088a83cf&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
import style0 from "./Home.vue?vue&type=style&index=0&id=088a83cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088a83cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBanner,VBottomSheet,VBtn,VCard,VCardText,VChip,VCol,VDataIterator,VIcon,VRow,VSheet,VSimpleTable,VTab,VTabItem,VTabs,VTabsItems})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
