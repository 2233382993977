<template>
  <v-row>
    <v-col cols="12">
      <v-col cols="11" v-if="!loggedInUser && !$vuetify.breakpoint.smAndDown">
        <v-banner
          elevation="6"
        >
          <v-icon
            slot="icon"
            color="primary"
            size="36"
          >
          mdi-details
          </v-icon>
          Know The Game All Ireland Hurling Predictor will run for the duration of the provincial championships 2025.<br/>Predict the winner and margin in each game to be in with a chance of winning while raising money for your club

          <template v-slot:actions>
            <v-btn
              color="primary"
              @click="goToFAQ"
            >
              More Info
            </v-btn>
          </template>
        </v-banner>
        </v-col>
    </v-col>
    <v-col v-if="!loggedInUser && $vuetify.breakpoint.smAndDown" cols="12">
      <v-bottom-sheet
        v-model="sheet"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-banner
          single-line
          elevation="6"
        >
          <v-icon
            slot="icon"
            color="primary"
            size="36"
          >
          mdi-details
          </v-icon>
          How to play Predictor Game

          <template v-slot:actions>
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              Info
            </v-btn>
          </template>
        </v-banner>
        </template>
        <v-sheet
          class="text-center"
          height="200px"
        >
            Know The Game All Ireland Hurling Predictor will run for 9 rounds of the Senior Hurling Championship 2025.<br/>Predict the winner and margin in each game to be in with a chance of winning while raising money for your club
            <v-btn
            class="mt-6"
            text
            color="primary"
            @click="goToFAQ"
          >
            More Details
          </v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="12"
    >
      <v-row>
        <v-col cols="12" v-if="!hasMadeSelection && getCompetitionPredictionsOpen" style="cursor: pointer;">
          <crypto-currency-card
            v-if="loggedInUser"
            v-ripple
            bg-card-color="primary"
            :heading="gameweekString"
            sub-heading="Click to enter your selection"
            :main-icon-text-color="$vuetify.breakpoint.xsOnly ? 'primary--text text--lighten-2 text-h4' : 'primary--text text--lighten-2 text-h3'"
            main-icon-name="mdi-gesture-tap"
            @click.native="goToSelection"
          />
          <crypto-currency-card
            v-else
            v-ripple
            bg-card-color="secondary"
            heading="Log In/Sign Up"
            sub-heading="Log in to enter predictions"
            :main-icon-text-color="$vuetify.breakpoint.xsOnly ? 'secondary--text text--lighten-2 text-h4' : 'secondary--text text--lighten-2 text-h3'"
            main-icon-name="mdi-account-plus-outline"
            @click.native="signIn"
          />
        </v-col>
        <v-col cols="12">
          <chart-card
            title="Upcoming Fixtures"
            button-text=""
          >
            <template slot="chart">
              <v-col cols="12">
                <v-data-iterator
                  id="table"
                  :items="getFixtures"
                  :items-per-page.sync="itemsPerPage"
                  :page="page"
                  :hide-default-footer="getFixtures.length < 5 ? true : false"
                  :footer-props="{
                    itemsPerPageText: 'Display'
                  }"
                >
                  <template v-slot:default="props">
                    <v-row justify="center">
                      <v-col
                        v-for="(item, index) in props.items"
                        :key="index"
                        cols="12"
                        lg="8"
                        class="pa-0 mb-2 cursor-pointer"
                        @click="goToFixture(item.stage)"
                      >
                        <list-card-row
                          :fixture="item"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </template>
          </chart-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      lg="4"
      md="12"
    >
      <base-card v-if="loggedInUser">
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab
              v-for="league in getLeaguesSnapshot"
              :key="league.leagueId"
            >
              {{ league.leagueName }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="league in getLeaguesSnapshot"
              :key="league.leagueId"
            >
              <v-card flat>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-left pa-0">
                          Position
                        </th>
                        <th class="text-left pa-0">
                          Name
                        </th>
                        <th class="text-left pa-0">
                          Points
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="participant in league.particpantResultSnapshotDtoList"
                        :key="participant.id"
                        :class="{'first-position': participant.position === 1, 'own_position': participant.playerId === loggedInUser.userDetails.id}"
                      >
                        <td class="pa-0 ml-2 text-left">
                          <span class="pr-2">
                            <b> {{ participant.position == 0 ? '' : participant.position }} </b>
                          </span>
                          <v-chip
                            :class="
                              changeColor(
                                participant.positionChange
                              )
                            "
                            small
                            label
                            class="mr-2 px-2 secondary"
                          >
                            <v-icon class="white--text">
                              {{ arrowDisplay(participant.positionChange) }}
                            </v-icon>
                            {{ positionText(participant.positionChange) }}
                          </v-chip>
                        </td>
                        <td class="pa-0">
                          {{ participant.playerDisplayName }}
                        </td>
                        <td class="pa-0 ml-2">
                          <p
                            dark
                            class="mb-0"
                          >
                            {{ participant.totalPoints }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import ChartCard from '@/components/card/ChartCard'
  import ListCardRow from '@/components/card/listCard/ListCardRow'
  import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard.vue'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'Home',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Home',
    },
    components: {
      ChartCard,
      ListCardRow,
      CryptoCurrencyCard
    },
    mounted() {
      this.fetchFixturesSnapshot(this.$store.getters.getCompetitionId);
      if(this.loggedInUser && this.loggedInUser.userDetails){
        if(this.getOverallLeagueId){
          this.fetchLeaguesSnapshot(this.loggedInUser.userDetails.id);
          this.fetchGameWeekSelection({userId: this.loggedInUser.userDetails.id, gameweek: this.getCurrentGameweek, competitionId: this.$store.getters.getCompetitionId});
        } else {
          this.clearLeagueData();
        }
      }
    },
    watch: {
      loggedInUser(){
        if(this.loggedInUser && this.loggedInUser.userDetails){
          this.fetchLeaguesSnapshot(this.loggedInUser.userDetails.id);
        }
      },
      getCurrentGameweek(val){
        if(this.loggedInUser && this.loggedInUser.userDetails){
          this.fetchGameWeekSelection({userId: this.loggedInUser.userDetails.id, gameweek: val, competitionId: this.$store.getters.getCompetitionId});
        }
      }
    },
    data () {
      return {
        tab: null,
        itemsPerPage: 5,
        page: 1,
        sheet: false
      }
    },
    computed: {
      ...mapGetters(['loggedInUser', 'getFixtures', 'getLeaguesSnapshot', 'hasMadeSelection', 'getCurrentGameweek', 'getCompetitionPredictionsOpen', 'getOverallLeagueId']),
      gameweekString(){
        return 'Game Week ' + this.getCurrentGameweek;
      }
    },
    methods: {
      ...mapActions(["fetchFixturesSnapshot", "fetchLeaguesSnapshot", "fetchGameWeekSelection", "clearLeagueData"]),
      goToSelection () {
        this.$router.push('/app/hurling/selection/current')
      },
      goToFixture(stage){
        this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/matches/fixtures?competition=${stage}`)
      },
      signIn () {
        this.$router.push('/app/sessions/sign-in')
      },
      goToFAQ() {
        this.$router.push('/help/faq/signup')
      },
      getColor (points) {
        if(!this.ifValueExists(points)) { return ''};
        if (points % 2 == 0) {
          return 'danger--text'
        } else if (points[points.length] == 0) {
          return 'primary--text'
        } else {
          return 'success--text'
        }
      },
      changeColor (movement) {
        if(!this.ifValueExists(movement)) { return ''};
        if (movement < 0) {
          return 'danger white--text'
        } else if (movement == '0') {
          return 'primary white--text'
        } else {
          return 'success white--text'
        }
      },
      arrowDisplay (change) {
        if(!this.ifValueExists(change)) { return ''};
        if (change < 0) {
          return 'mdi-triangle-small-down'
        } else if (change == 0) {
          return 'mdi-minus'
        } else {
          return 'mdi-triangle-small-up'
        }
      },
      positionText (change) {
        if(!this.ifValueExists(change)) { return ''};
        if (change == 0){
          return '';
        } else {
          return Math.abs(change);
        }
      },
      ifValueExists(value){
        if (value !== null && value !== undefined) {
            return true;
        } else {
            return false;
        }
      }
    },
  }
</script>

<style scoped>
.first-position{
  background-color: darkseagreen;
}

.own_position {
  color: aqua;
  background-color: darkgray;
  padding-left: 5px;
}
</style>
